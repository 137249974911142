import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as sessionActions from '@pitchbooking-dev/pb-shared/lib/reducers/sessionReducer';
import { useMutation } from '@tanstack/react-query';
import usePitchbookingUser from './usePitchbookingUser';
import * as calendarActions from '../reducers/calendarReducer';
import useToast from './useToast';
import useCompany from './useCompany';
import { updateCompanyUser } from '../services/usersServices';
import * as companyActions from '../reducers/companiesReducer';

export const useSiteSelector = () => {
  const dispatch = useDispatch();
  const toast = useToast();
  const { id: userId, preferences } = usePitchbookingUser();
  const companyInfo = useCompany();
  const sites = useSelector((state) => state.calendar.sites);
  const [selectedSites, setSelectedSites] = useState([]);
  const [hasChangedSite, setHasChangedSite] = useState(false);

  // Change selected site and fetch corresponding data
  const changeSite = (selectedDate = null) => {
    dispatch(sessionActions.changeSelectedSiteId(selectedSites));
    dispatch(calendarActions.getCalendarUsage(selectedSites, selectedDate));
  };

  // Fetch company sites
  useEffect(() => {
    dispatch(calendarActions.getCompanySites());
  }, []);

  // if user has preferred sites, set them as selected sites
  useEffect(() => {
    if (preferences?.preferredSites?.length > 0) {
      setSelectedSites(preferences.preferredSites);
    }
  }, [preferences]);

  // If user has no preferred sites, set site[0] as selected
  useEffect(() => {
    if (!preferences?.preferredSites && sites?.length > 0) {
      setSelectedSites([sites[0]?.id]);
      // TODO: Could maybe set preferred POS site here
    }
  }, [preferences, sites]);

  // If selected sites change, change site
  useEffect(() => {
    if (selectedSites.length > 0 && !hasChangedSite) {
      changeSite(); // Call changeSite only once when selectedSites has a value
      setHasChangedSite(true); // Set the flag to true to prevent re-triggering
    }
  }, [selectedSites.length, hasChangedSite]); // Depend on length and flag

  const updateSelectedSites = (newSelectedSites) => {
    setSelectedSites(newSelectedSites);
  };

  const mutation = useMutation({
    mutationFn: async () => {
      const data = {
        updateUserPreferences: true,
        userPreferences: {
          ...preferences,
          preferredSites: selectedSites,
        },
      };

      const res = await updateCompanyUser(companyInfo.id, userId, data);
      if (res.status !== 200) {
        throw new Error('Error');
      }
    },
    onSuccess: () => {
      dispatch(companyActions.getCurrentUsersCompanies(userId));
      toast.trigger({
        type: 'success',
        message: 'Default calendar view saved',
      });
    },
    onError: () => {
      toast.trigger({
        type: 'error',
        message: 'Error saving view. Please refresh the page and try again',
      });
    },
  });

  return {
    sites, // All company sites
    selectedSites, // Users preferred sites || default first site
    changeSite, // Change selected site and fetch corresponding data
    updateSelectedSites, // Enable the user to update selected sites
    savePreferredSites: mutation.mutate, // Save selected sites as preferred
    savePreferredSitesLoading: mutation.isLoading, // Save selected sites as preferred
  };
};

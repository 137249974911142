import React from 'react';
import PropTypes from 'prop-types';
import { DateRangePicker } from 'react-dates';
import '../../styles/calendar.css';

class DateRangePickerWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focusedInput: null,
    };
  }

  render() {
    const { focusedInput } = this.state;
    const {
      endDate,
      noBorder,
      startDate,
      minimumNights,
      onDatesChange,
      displayFormat,
      isOutsideRange,
      numberOfMonths,
      withFullScreenPortal,
    } = this.props;

    return (
      <div style={{ flexShrink: 0 }}>
        <DateRangePicker
          startDateId="bookingsStartTime"
          endDateId="bookingsEndTime"
          endDate={endDate}
          noBorder={noBorder}
          startDate={startDate}
          isOutsideRange={isOutsideRange}
          focusedInput={focusedInput}
          minimumNights={minimumNights}
          onDatesChange={onDatesChange}
          displayFormat={displayFormat}
          numberOfMonths={numberOfMonths}
          onFocusChange={(newFocusedInput) => this.setState({ focusedInput: newFocusedInput })}
          withFullScreenPortal={withFullScreenPortal}
        />
      </div>
    );
  }
}

DateRangePickerWrapper.propTypes = {
  noBorder: PropTypes.bool.isRequired,
  endDate: PropTypes.shape().isRequired,
  startDate: PropTypes.shape().isRequired,
  onDatesChange: PropTypes.func.isRequired,
  isOutsideRange: PropTypes.func.isRequired,
  displayFormat: PropTypes.number.isRequired,
  minimumNights: PropTypes.number.isRequired,
  numberOfMonths: PropTypes.number.isRequired,
  withFullScreenPortal: PropTypes.bool.isRequired,
};

export default DateRangePickerWrapper;

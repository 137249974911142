import moment from 'moment';
import ArraySort from 'array-sort';
import { getCurrencySymbol } from '@pitchbooking-dev/pb-shared/lib/helpers';
import JSON2CSV from 'json2csv';
import FileDownload from 'js-file-download';
import { invoiceNumberFormatted } from '@pitchbooking-dev/pb-shared/lib/helpers/calculationHelpers';
import DownloadPdf from '../components/DownloadPdf';
import { omitFormProperties } from '../utils';

const dateTimeFormat = 'YYYY-MM-DD HH:mm:ss';
const createDateTimeInMomentFormat = (dateTime) => moment(dateTime, dateTimeFormat);

export const createDateWithTime = (date, time) => {
  let newDate;
  if (time) {
    const hour = time.substring(0, 2);
    const minute = time.substring(3, 5);
    newDate = createDateTimeInMomentFormat(date);
    newDate.set({ hour, minute });
  }
  return newDate;
};

export const createStartTimeString = (time) => {
  const startTimeFormatted = createDateWithTime(moment(), time).startOf('minute').format('HH:mm');
  return startTimeFormatted;
};
export const createEndTimeString = (time) => {
  const endTimeFormatted = createDateWithTime(moment(), time).endOf('minute').add(1, 'seconds').format('HH:mm');
  return endTimeFormatted;
};
export const createTimeRangeString = (startTime, endTime, addSecond = true) => {
  if (!startTime && !endTime) { return ''; }
  const startTimeWithDate = createDateWithTime(moment(), startTime);
  const startTimeFormatted = moment(startTimeWithDate).startOf('minute').format('HH:mm');
  const endTimeWithDate = createDateWithTime(moment(), endTime);
  const secondsToAdd = addSecond ? 1 : 0;
  let endTimeFormatted = moment(endTimeWithDate).endOf('minute').add(secondsToAdd, 'seconds').format('HH:mm');
  if (endTime?.substring(3, 5) === '00') {
    endTimeFormatted = moment(endTimeWithDate).format('HH:mm');
  }
  let timeRangeString = `${startTimeFormatted} - ${endTimeFormatted}`;
  if (startTime && !endTime) {
    timeRangeString = `From ${startTimeFormatted}`;
  } else if (!startTime && endTime) {
    timeRangeString = `Until ${startTimeFormatted}`;
  }

  return timeRangeString;
};
export const createTimeRangeStringForSchedules = (startTime, endTime) => {
  const startTimeWithDate = createDateWithTime(moment(), startTime);
  const startTimeFormatted = moment(startTimeWithDate).startOf('minute').format('HH:mm');
  const endTimeWithDate = createDateWithTime(moment(), endTime);
  let endTimeFormatted = moment(endTimeWithDate).format('HH:mm');
  if (endTime.substring(3, 5) === '00') {
    endTimeFormatted = moment(endTimeWithDate).format('HH:mm');
  }
  return `${startTimeFormatted} - ${endTimeFormatted}`;
};

export const createWeekendCutoffFormat = (day, time) => {
  const formattedDay = moment(day).format('dddd');
  const formattedTime = moment(time).format('HH:mm');
  const weekendCutoff = `${formattedDay},${formattedTime}`;
  return weekendCutoff;
};

export const paymentStatusColourGenerator = (paymentStatus) => {
  let paymentStatusStyleColor = 'inherit';
  switch (paymentStatus) {
    case 'UNPAID':
      paymentStatusStyleColor = '#AC372F';
      break;
    case 'Not Set':
      paymentStatusStyleColor = '#FFBC13';
      break;
    case 'SUBSCRIPTION':
      paymentStatusStyleColor = '#6699FF';
      break;
    case 'NOT_CHARGED':
    case 'BANKTRANSFER':
    case 'PAYPAL':
    case 'CASH':
    case 'ZELLE':
    case 'PLEI':
    case 'STRIPE':
    case 'CARD':
      paymentStatusStyleColor = '#1EBE51';
      break;
    default:
      paymentStatusStyleColor = 'inherit';
      break;
  }
  return paymentStatusStyleColor;
};

export const paymentStatusTextColourGenerator = (paid, paymentStatusText) => {
  let paymentStatusStyleColor = 'inherit';
  if (paid && paymentStatusText !== 'Not Charged') {
    paymentStatusStyleColor = '#1EBE51';
  } else {
    switch (paymentStatusText) {
      case 'Unpaid':
      case 'Inactive - manual payment required':
      case 'Payment failed':
        paymentStatusStyleColor = '#AC372F';
        break;
      case 'Stripe payment scheduled':
        paymentStatusStyleColor = '#6699FF';
        break;
      case 'Unpaid - Invoiced externally':
      case 'Inactive - payment setup required':
        paymentStatusStyleColor = '#FFBC13';
        break;
      case 'Stripe - No payment information':
      case 'Voided':
        paymentStatusStyleColor = '#6e6f70';
        break;
      case 'Not Charged':
        paymentStatusStyleColor = '#636363';
        break;
      case 'Invoiced (Legacy)':
      default:
        paymentStatusStyleColor = 'inherit';
        break;
    }
  }
  return paymentStatusStyleColor;
};

export const destructureWeekendCutoffFormat = (weekendCutoff) => {
  const weekendCutoffFormatted = weekendCutoff.split(',');
  switch (weekendCutoffFormatted[0]) {
    case 'Monday':
      weekendCutoffFormatted[0] = '1';
      break;
    case 'Tuesday':
      weekendCutoffFormatted[0] = '2';
      break;
    case 'Wednesday':
      weekendCutoffFormatted[0] = '3';
      break;
    case 'Thursday':
      weekendCutoffFormatted[0] = '4';
      break;
    case 'Friday':
      weekendCutoffFormatted[0] = '5';
      break;
    case 'Saturday':
      weekendCutoffFormatted[0] = '6';
      break;
    case 'Sunday':
      weekendCutoffFormatted[0] = '0';
      break;
    default:
      weekendCutoffFormatted[0] = '1';
      break;
  }
  weekendCutoffFormatted[1] = createDateWithTime(moment(), weekendCutoffFormatted[1]);
  return weekendCutoffFormatted;
};

export const generateValidityText = (row, timezone = 'Europe/London') => {
  let validityText;
  if (row === undefined) {
    return '';
  }
  if (row.validFrom && row.validTo) {
    if (moment(row.validFrom).format('ll') === moment(row.validTo).format('ll')) {
      validityText = `${moment(row.validFrom).tz(timezone).format('ll')}`;
    } else {
      validityText = `${moment(row.validFrom).tz(timezone).format('ll')} - ${moment(row.validTo).tz(timezone).format('ll')}`;
    }
  } else if (row.validFrom && !row.validTo) {
    validityText = `From ${moment(row.validFrom).tz(timezone).format('ll')}`;
  } else if (!row.validFrom && row.validTo) {
    validityText = `Until ${moment(row.validTo).tz(timezone).format('ll')}`;
  } else if (!row.validFrom && !row.validTo) {
    validityText = 'Indefinite';
  } else if (row.recurrence && row.recurrence === 'ALWAYS') {
    validityText = 'Always';
  } else {
    validityText = '';
  }
  return validityText;
};

export const canIncludeFilter = (categoryIndex, collection, reservation, paymentStatusText) => {
  let canInclude = true;
  switch (categoryIndex) {
    case 0: // Facilities
      if (collection.length > 0 && !collection.includes(reservation.facility.name)) {
        canInclude = false;
      }
      break;
    case 1: // Sports
      if (collection.length > 0 && !collection.includes(reservation.sport)) {
        canInclude = false;
      }
      break;
    case 2: // Names
      if (collection.length > 0 && !collection.includes(reservation.full_name)) {
        canInclude = false;
      }
      break;
    case 3: // Payment Statuses
      if (collection.length > 0 && !collection.includes(paymentStatusText)) {
        canInclude = false;
      }
      break;
    default:
      canInclude = false;
      break;
  }
  return canInclude;
};
export const generatePriceModifierString = (pm, currencySym = '£') => {
  const modifierType = pm.modifierType ? pm.modifierType : '';
  const absolute = pm && pm.priceType === 'ABSOLUTE' && pm?.value > 0 ? '+' : '';
  const percentage = pm && pm.priceType === 'PERCENTAGE' ? '%' : '';
  const value = modifierType === 'FLOODLIGHTS' && pm.floodlights ? pm.floodlights?.price : pm?.value;
  const price = value ? `(${absolute}${percentage ? '' : currencySym}${parseFloat(value * (pm.subFacility_quantity ? pm.subFacility_quantity : 1)).toFixed(2)}${percentage}${percentage ? '' : `/${pm.pricePer}`})` : `${percentage ? '' : currencySym}0`;
  const ageGroupBookingModifier = pm.ageGroupBookingModifier ? pm.ageGroupBookingModifier.name : '';
  const eventBookingModifier = pm.eventBookingModifier ? pm.eventBookingModifier.name : '';

  let day = [];
  if (pm.days && pm.days.length > 1) {
    day = pm.days.map((day) => moment().day(day).format('ddd')).join(', ');
  } else if (pm.days && pm.days.length === 1) {
    day = moment().day(pm.days[0]).format('dddd');
  } else {
    day = '';
  }

  const quantity = pm.quantity ? `Quantity: ${pm.quantity}` : '';
  const times = createTimeRangeString(pm.startTime, pm.endTime, false);
  const duration = pm.duration ? `Duration: ${pm.duration}` : '';
  const subFacilityQuantity = pm.subFacility_quantity
    ? `Units: ${pm.subFacility_quantity} @ ${currencySym}${(pm?.value).toFixed(2)}/unit`
    : '';

  let priceModifierComponents = [];

  if (modifierType === 'FLOODLIGHTS') {
    priceModifierComponents = priceModifierComponents
      .concat('COMBINATION')
      .concat(pm?.value ? `(${absolute}${percentage ? '' : currencySym}${parseFloat(pm?.value).toFixed(2)}${percentage}${percentage ? '' : `/${pm.pricePer}`})` : `${percentage ? '' : currencySym}0`)
      .concat('W/');
  }

  priceModifierComponents = priceModifierComponents
    .concat(modifierType)
    .concat(price)
    .concat(ageGroupBookingModifier)
    .concat(eventBookingModifier)
    .concat(day)
    .concat(times)
    .concat(duration)
    .concat(quantity)
    .concat(subFacilityQuantity);

  return priceModifierComponents.join(' ');
};

export function parseSubscriptionRowsForDownload(columns, data) {
  const colHeaders = [
    'subscriptionName',
    'Facility Name',
    'Name',
    'User',
    'Amount',
    'Validity',
    'Day',
    'Time',
    'Confirmed',
    'Type',
  ];
  const cols = colHeaders;
  const rows = [];

  data.forEach((n) => {
    const row = {
      [cols[0]]: n?.subscriptionName,
      [cols[1]]: n?.accessRestriction.facilities[0].name,
      [cols[2]]: `${n?.user?.firstName} ${n?.user?.lastName}`,
      [cols[3]]: n?.user?.email,
      [cols[4]]: (n?.amount / 100).toFixed(2),
      [cols[5]]: generateValidityText(
        n.accessRestriction, n.accessRestriction?.facilities[0].timezone,
      ),
      [cols[6]]: moment().weekday(parseInt(n.accessRestriction.weekday, 10)).format('dddd'),
      [cols[7]]: n?.accessRestriction?.startTime,
      [cols[8]]: n?.status,
      [cols[9]]: n?.type === 'PAYP' ? 'Invoice / Pay on Arrival' : 'Automatic',
    };
    rows.push(row);
  });
  return { colHeaders, rows };
}

export function parseBookingRowsForDownload(columns, data) {
  const colHeaders = [
    'Reservation ID',
    'Facility Name',
    'Sport',
    'Start Date',
    'Start Time',
    'End Time',
    'Name',
    'Team Name',
    'Email',
    'Age Group',
    'Event Type',
    'Amenities',
    'Addons',
    'Payment Status',
    'Price',
    'Total Ex Tax',
    'Tax',
    'Notes',
    'Created At',
    'Date & Time',
    'Site ID',
    'Status',
    'Invoice Number',
    'Order Id',
    'Refund Id',
    'Cancelled By',
    'Cancellation Reason',
    'Cancellation Date',
    'Form Data',
  ];
  const includeCostCodes = data.filter(
    (d) => d.costCode !== null && d.costCode !== undefined,
  ).length > 0;
  const includeCancellationReason = data.filter(
    (d) => d.cancellationReason !== null && d.cancellationReason !== undefined,
  ).length > 0;
  if (includeCostCodes) { colHeaders.push('Cost Code'); }
  if (includeCancellationReason) { colHeaders.push('Cancellation Reason'); }

  const cols = colHeaders;
  const rows = [];

  data.forEach((n) => {
    const totalPriceFormatted = (n.total !== null) ? (n.total).toFixed(2) : null;
    // added next two lines
    const totalExTax = (n.totalExTax !== null) ? (n.totalExTax).toFixed(2) : null;
    const tax = (n.tax !== null) ? (n.tax).toFixed(2) : null;
    let ageGroup = 'Not Set';
    let eventType = 'Not Set';
    if (n.allocations[0] && n.allocations[0].ageGroupBookingModifier) {
      ageGroup = n.allocations[0].ageGroupBookingModifier.name;
    }
    if (n.allocations[0] && n.allocations[0].eventBookingModifier) {
      eventType = n.allocations[0].eventBookingModifier.name;
    }
    const { startTime, endTime } = n;
    const row = {
      [cols[0]]: n.id || `${n.subscriptionId}-${moment(startTime).format('YYYY-MM-DD')}`,
      [cols[1]]: n.subFacilities ? n.subFacilities.map((sf) => sf.name).join(', ') : n.facilityName,
      [cols[2]]: n.sport || 'Not Set',
      [cols[3]]: moment(startTime).tz(n?.facility?.timezone).format('DD-MM-YYYY'),
      [cols[4]]: moment(startTime).tz(n?.facility?.timezone).format('HH:mm'),
      [cols[5]]: moment(endTime).tz(n.facility.timezone).add(1, 's').format('HH:mm'),
      [cols[6]]: n.fullName,
      [cols[7]]: n.user.teamName || 'Not Set',
      [cols[8]]: n.user.email,
      [cols[9]]: ageGroup,
      [cols[10]]: eventType,
      [cols[11]]: (n.amenityNames ? n.amenityNames.join(', ') : ''),
      [cols[12]]: (n.addonNames ? n.addonNames.join(', ') : ''),
      [cols[13]]: n.paymentStatusText,
      [cols[14]]: totalPriceFormatted,
      // added next two lines
      [cols[15]]: totalExTax,
      [cols[16]]: tax,
      [cols[17]]: (n.notes),
      [cols[18]]: moment(n.createdAt).format('DD-MM-YYYY'),
      [cols[19]]: `${moment(startTime).format('DD-MM-YYYY')}, ${moment(startTime).format('HH:mm')} - ${moment(endTime).add(1, 's').format('HH:mm')}`,
      [cols[20]]: n.facility?.siteId,
      [cols[21]]: n.status,
      // eslint-disable-next-line no-nested-ternary
      [cols[22]]: n?.invoiceUnpaid
        ? invoiceNumberFormatted(n.invoiceUnpaid.invoiceNumber)
        : n?.invoicePaid
          ? invoiceNumberFormatted(n.invoicePaid.invoiceNumber)
          : 'Not Invoiced',
      [cols[23]]: n?.orders?.filter((order) => order?.status === 'CREATED').map((order) => order?.id).join(', '),
      [cols[24]]: n?.orders?.filter((order) => order?.status === 'REFUNDED').map((order) => order?.id).join(', '),
      [cols[25]]: n?.cancelledByUser ? `${n?.cancelledByUser?.firstName} ${n?.cancelledByUser?.lastName}` : '',
      [cols[26]]: n?.cancellationReason || 'No Reason Specified',
      [cols[27]]: n?.cancellationDate ? moment(n?.cancellationDate).format('ddd D MMM YYYY h:mm a') : '',
      [cols[28]]: n.allocations.map((a) => (a.formData ? JSON.stringify(a.formData) : '')),
      [cols[28]]: n?.allocations && n?.allocations?.length > 0
        ? n?.allocations?.map((a) => (a?.formData ? JSON.stringify(omitFormProperties(a?.formData)) : '')).join(', ')
        : '',
    };
    if (includeCostCodes) { row[cols[29]] = n.costCode; }
    if (!includeCostCodes && includeCancellationReason) { row[cols[30]] = n.cancellationReason || 'No Reason Specified'; }
    if (includeCostCodes && includeCancellationReason) { row[cols[31]] = n.cancellationReason || 'No Reason Specified'; }
    rows.push(row);
    ArraySort(rows, [cols[3], cols[1], cols[4]]);
  });

  return { colHeaders, rows };
}

export function parsePayoutTransactionsRowsForDownload(columns, data, timezone, id) {
  const colHeaders = [
    'Item',
    'Booking Date',
    'Email',
    'Charge ID',
    'Total',
    'TotalExTax',
    'Tax',
    'Fee',
    'Date Processed',
    'Time Processed',
    'Cost Code',
    'Payout ID',
    'Team Name',
    'User Notes',
  ];

  const cols = colHeaders;
  const rows = [];
  data.sort((a, b) => (a.chargeId > b.chargeId ? 1 : -1));
  data.forEach((n, index) => {
    let itemText = n.item;
    if (n?.type === 'refund') { itemText = `Refund: ${itemText}`; }
    if (n?.type === 'partial refund') { itemText = `Partial Refund: ${itemText}`; }
    const total = (n.total && n?.total !== null) ? (n.total).toFixed(2) : null;
    const totalExTax = (n.totalExTax && n?.totalExTax !== null) ? (n.totalExTax).toFixed(2) : null;
    const tax = (n.tax && n?.tax !== null) ? (n.tax).toFixed(2) : null;

    const row = {
      [cols[0]]: itemText,
      [cols[1]]: n?.startTime ? moment(n.startTime).tz(timezone).format('ddd D MMM YYYY h:mm a') : null,
      [cols[2]]: n?.email,
      [cols[3]]: n?.chargeId,
      [cols[4]]: total,
      [cols[5]]: totalExTax,
      [cols[6]]: tax,
      [cols[7]]: (n?.chargeId === data[index - 1]?.chargeId ? null : n?.fee / 100) || (n.type === 'stripe fee' ? n?.fee / 100 : null),
      [cols[8]]: moment(n?.paidAt).tz(timezone).format('D MMM YY'),
      [cols[9]]: moment(n?.paidAt).tz(timezone).format('LT'),
      [cols[10]]: n?.costCode,
      [cols[11]]: id,
      [cols[12]]: n?.teamName,
      [cols[13]]: n?.userNotes?.value,
    };
    rows.push(row);
    ArraySort(rows, [cols[7], cols[1], cols[0]]);
  });
  return { colHeaders, rows };
}

export function generateCustomMaterialTableDownloaders(parser, tablename = 'Booking', timezone, selectedPdfColumns = null, id = null) {
  const downloaders = {
    exportCsv: (columns, data) => {
      const { colHeaders, rows } = parser(columns, data, timezone, id);
      const csv = JSON2CSV({ data: rows, fields: colHeaders, excelStrings: false });
      const timestamp = moment(new Date()).format('YYYY-MM-DD HH:mm');
      const filename = `Pitchbooking ${tablename} Export (${timestamp}).csv`;
      FileDownload(csv, filename);
    },
    exportPdf: (columns, data) => {
      const { colHeaders, rows } = parser(columns, data, timezone);
      let selectedHeaders = colHeaders;
      if (selectedPdfColumns && selectedPdfColumns.length > 0) {
        selectedHeaders = selectedPdfColumns;
      }
      const selectedData = [];
      rows.forEach((n) => {
        const thisRow = [];
        selectedHeaders.forEach((c) => { thisRow[selectedHeaders.indexOf(c)] = n[c]; });
        selectedData.push(thisRow);
      });
      const timestamp = moment(new Date()).format('YYYY-MM-DD HH:mm');
      const filename = `Pitchbooking ${tablename} Export (${timestamp})`;
      DownloadPdf(selectedHeaders, selectedData, filename);
    },
  };
  return downloaders;
}

export function generatePayoutTransactionsDownloaders(timezone, stripePayoutId) {
  return generateCustomMaterialTableDownloaders(
    parsePayoutTransactionsRowsForDownload,
    'Payout Transactions',
    timezone,
    null,
    stripePayoutId,
  );
}

export function parseAttendeeRowsForDownload(data, company) {
  const currencySym = getCurrencySymbol(company.currency);
  const colHeaders = [];
  data.forEach((res) => {
    if (res.allocations[0].formData) {
      const cleanedFormData = omitFormProperties(res?.allocations[0]?.formData);
      const fields = Object.keys(cleanedFormData);
      fields.forEach((field) => {
        if (!colHeaders.includes(field)) {
          colHeaders.push(field);
        }
      });
    }
  });
  const cols = colHeaders;
  cols.push('booked', 'bookedBy', 'createdAt', 'price', 'status', 'email');
  const rows = [];

  data.forEach((n, i) => {
    const allocations = n.allocations.filter((a) => a.type === 'EVENT');
    if (allocations[0]) {
      colHeaders.forEach((col, index) => {
        if (col === 'createdAt') {
          rows[i] = {
            ...rows[i],
            [cols[index]]: moment(allocations[0].createdAt).format('L'),
          };
        } else if (col === 'price') {
          rows[i] = {
            ...rows[i],
            [cols[index]]: `${currencySym}${allocations[0].price}`,
          };
        } else if (col === 'bookedBy') {
          rows[i] = {
            ...rows[i],
            [cols[index]]: `${n.fullName}`,
          };
        } else if (col === 'status') {
          rows[i] = {
            ...rows[i],
            [cols[index]]: `${n.status}`,
          };
        } else if (col === 'booked') {
          rows[i] = {
            ...rows[i],
            [cols[index]]: `${n.facilityName}`,
          };
        } else if (col === 'email') {
          rows[i] = {
            ...rows[i],
            [cols[index]]: `${n.user.email}`,
          };
        } else if (col === 'consent') {
          rows[i] = {
            ...rows[i],
            [cols[index]]: `${allocations[0]?.formData?.[col]}`,
          };
        } else {
          rows[i] = {
            ...rows[i],
            [cols[index]]: allocations[0].formData && allocations[0].formData?.[col]
              ? allocations[0].formData?.[col] : null,
          };
        }
      });
    }
  });

  return { colHeaders, rows };
}

export function PrintDiv(elem, title) {
  const mywindow = window.open('', 'PRINT', 'height=400,width=600');
  mywindow.document.write(`
    <html>
    <style type="text/css">
      @media print {
        .excludeFromPrint { display: none; }
        #${elem} { display: block; }
      }
    </style>
      <head>   
        <title>${title}</title>
      </head>
      <body>
        <div>
          <h1>${title}</h1>
          ${document.getElementById(elem).innerHTML}  
        </div>
       
        </br>
        </br>
        </br>

        <div class="excludeFromPrint">
          <button type="button" onclick={window.print()}>Print</button>
          <button type="button" onclick={window.close()}>Close</button>
        </div>
      </body>
    </html> 
  `);

  mywindow.document.close();
  mywindow.focus();

  return true;
}

export function toProperCase(s) {
  return s.toLowerCase().replace(/^(.)|\s(.)/g, ($1) => $1.toUpperCase());
}

export const calculatePitchbookingPrice = (
  units, isInternal = false, externalUnits = 0, bulkDiscount = false,
) => {
  if (isInternal) {
    if (externalUnits > 0 || units > 8) {
      return units * 12.5;
    }
    return 100;
  }

  if (units <= 0) return 0;
  if (units === 1) return 100;
  if (units === 2) return 150;
  if (units >= 10 && bulkDiscount) return 25 * (units);
  return 150 + 25 * (units - 2);
};

export const calculateFullPitchbookingPrice = (
  externalFacilities, internalFacilities = 0, bulkDiscount = false, discount = 0,
) => {
  const internalsTotalPrice = Math.max(0, 12.5 * internalFacilities);
  let externalsTotalPrice = Math.max(0, 25 * externalFacilities);
  if (!bulkDiscount) {
    if (externalFacilities === 1) {
      externalsTotalPrice = 100;
    } else if (externalFacilities === 2) {
      externalsTotalPrice = 150;
    } else if (externalFacilities > 2) {
      externalsTotalPrice = 150 + 25 * (externalFacilities - 2);
    }
  }
  const subTotal = Math.max(100, internalsTotalPrice + externalsTotalPrice);
  return subTotal - discount;
};

export const generateConfigurationNote = (facility) => {
  let configurationNote = null;
  if (facility?.splitType !== 'NONE') {
    switch (facility?.splitType) {
      case 'USER_SELECTION':
        configurationNote = 'Fractional selection';
        break;
      case 'AUTOMATIC_SELECTION':
        configurationNote = 'Manual selection';
        break;
      case 'MULTIPLE_SELECTION':
        configurationNote = 'Multiple selection';
        break;
      case 'HIDDEN_AUTOMATIC_SELECTION':
        configurationNote = 'Hidden selection';
        break;
      default:
        configurationNote = 'Unspecified selection';
        break;
    }
    if (facility?.subUnits && facility.subUnits > 0) {
      configurationNote += ` (x${facility.subUnits})`;
    }
  }

  if (facility?.isOverlapParent && facility?.parentFacilityId) {
    configurationNote = [configurationNote, 'Middle Overlap'].filter((i) => i !== null).join(' ');
  } else if (facility?.parentFacilityId) {
    configurationNote = [configurationNote, 'Child Overlap'].filter((i) => i !== null).join(' ');
  } else if (facility?.isOverlapParent) {
    configurationNote = [configurationNote, 'Parent Overlap'].filter((i) => i !== null).join(' ');
  }

  if (facility?.hasForcibleLinkedFacility) {
    configurationNote = [configurationNote, 'Forced link to facility'].filter((i) => i !== null).join('. ');
  }
  if (facility?.isForcibleLinkedFacility) {
    configurationNote = [configurationNote, 'Linked facility'].filter((i) => i !== null).join('. ');
  }

  return { note: configurationNote };
};

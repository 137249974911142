import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import CloseDialogIcon from '../../../shared-components/CloseDialogIcon';
import PrintButton from '../../../components/PrintButton';

const ViewAttendeeDialog = ({ formData, buttonText }) => {
  const [open, setOpen] = useState(false);
  const isMobile = window.innerWidth < 768;
  const formDataArray = Object.entries(formData);

  const handleRequestClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button onClick={() => setOpen(true)}>
        <DescriptionIcon style={{ marginRight: buttonText ? '0.2rem' : 0 }} />
        {buttonText}
      </Button>
      <Dialog
        open={open}
        fullScreen={isMobile}
        maxWidth="md"
        onClose={() => setOpen(false)}
      >
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <DialogActions variant="none">
            <CloseDialogIcon onClick={() => handleRequestClose()} />
          </DialogActions>
        </div>
        <DialogContent
          style={{ width: isMobile ? 'auto' : '750px' }}
        >
          {/* Title */}
          <div style={{ display: 'flex', justifyContent: 'center', margin: '1rem 0rem' }}>
            <Typography variant="h5" gutterBottom>
              Additional Details Form
            </Typography>
          </div>

          {/* Form Data */}
          <div style={{ display: 'flex', flexDirection: 'column', margin: '1rem 2rem' }}>
            {formDataArray?.map((item) => (
              <div>
                <Typography variant="h6" gutterBottom>
                  {item[0].replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}
                  {' '}
                  :
                  {' '}
                  {typeof item[1] === 'boolean' ? item[1].toString() : item[1]}
                </Typography>
              </div>
            ))}
          </div>

          {/* Button */}
          <div style={{ display: 'flex', justifyContent: 'center', margin: '2rem 0rem' }}>
            <PrintButton />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

ViewAttendeeDialog.propTypes = {
  formData: PropTypes.shape().isRequired,
  buttonText: PropTypes.string,
};

ViewAttendeeDialog.defaultProps = {
  buttonText: null,
};

const mapStateToProps = (state) => ({
  invoice: state.invoices.invoice,
});

const mapDispatchToProps = (dispatch) => ({
  getInvoiceInformation: (id) => dispatch(id),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewAttendeeDialog);

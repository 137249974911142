import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactToPrint from 'react-to-print';
import PropTypes from 'prop-types';
import { Button, Typography, CircularProgress } from '@material-ui/core';
import Calendar from '@pitchbooking-dev/pb-shared/lib/components/Calendar';
import moment from 'moment';
import * as actions from '../../reducers/calendarReducer';
import BookingCreationDialog from '../bookings/components/CreationStepper/BookingCreationDialog';
import DateWithSitesWrapper from '../../components/Date/DateWithSitesWrapper';
import ActionBar from '../../components/ActionBar';
import NotesDialog from '../../components/NotesDialog';
import RecordPaymentDialog from '../bookings/components/RecordPaymentDialog';
import * as reservationsActions from '../../reducers/reservationsReducer';
import { getSubscriptionService } from '../../services/subscriptionsServices';
import { updateSelectedRows, resetSelectedRows } from '../../reducers/bookingsReducer';
import { useToast, useCompany } from '../../hooks';
import BlockBookingCreationDialog from '../subscriptions/components/BlockBookingCreationDialog';

export const CalendarView = ({ history }) => {
  const toast = useToast();
  const dispatch = useDispatch();
  const calendarRef = useRef();
  const companyInfo = useCompany();
  const { calandarPaymentDialogOpen } = useSelector((state) => state.reservation);
  const { selectedDate } = useSelector((state) => state.session);
  const { selectedRows } = useSelector((state) => state.bookings);
  const {
    calendarUsage, isLoading, calendarReservationCreationLoading,
  } = useSelector((state) => state.calendar);
  const { notes } = calendarUsage;
  const [subscriptionLink, setSubscriptionLink] = useState(null);
  const [subscription, setSubscription] = useState();

  const sites = useSelector((state) => state.calendar.sites); // All company sites

  const handleTimeslotClick = companyInfo.products?.calendarInteractable === 'ENABLED' ? (event) => {
    dispatch(reservationsActions.toggleManagerReservationsCalendar(event));
  } : null;

  const getSubscriptionData = async (selectedSubscription) => {
    if (selectedSubscription) {
      await getSubscriptionService(companyInfo.id, selectedSubscription.id).then((response) => {
        if (response.data) {
          setSubscription({
            ...response.data,
            slot: moment(`${moment(selectedDate).format('YYYY-MM-DD')} ${selectedSubscription.startTime}`, 'YYYY-MM-DD hh:mm A').format(),
          });
        } else {
          toast.trigger({
            type: 'error',
            message: 'An error occurred while attempting to handle your request. Please try again, if the issues persists please contact us.',
          });
          dispatch(reservationsActions.handleCalendarPaymentDialog(false));
        }
      });
    }
  };

  const requestMultipleRecordPayment = async () => {
    await dispatch(
      reservationsActions.recordMultipleReservationPayment(
        [{ ...subscription, type: 'SUBSCRIPTION' }],
        false, null,
      ),
    );
  };

  return (
    <div>
      <ActionBar>
        <DateWithSitesWrapper
          afterChange={() => dispatch(actions.getCalendarUsage())}
          sites={sites}
          disabled={isLoading}
          calendarIsLoading={isLoading}
          showTodayButton
        />
        <NotesDialog date={selectedDate} notes={notes} />
      </ActionBar>
      <div
        id="daily-calendar"
        ref={calendarRef}
      >
        <Calendar
          {...calendarUsage}
          timezone={companyInfo.timezone}
          isLoading={isLoading}
          onEventClick={(e) => {
            if (e.type === 'SUBSCRIPTION') {
              dispatch(reservationsActions.handleCalendarPaymentDialog(true));
              dispatch(resetSelectedRows());
              const selectedRowShape = {
                ...e,
                slot: e.allocations?.[0]?.startTime,
                id: e.id ? e.id : e.subscriptionId,
                userId: e.user.id,
                allocations: e.allocations.concat(e.amenities),
                totalPaid: e.partialPaymentOrders?.reduce(
                  (acc, curr) => acc + (curr.total / 100), 0,
                ) ?? 0,
              };
              dispatch(updateSelectedRows(selectedRowShape));
              // setSubscriptionReservation(selectedRowShape);
              getSubscriptionData(e);
              setSubscriptionLink(e.navigation);
            } else {
              history.push(e.navigation);
            }
          }}
          company={companyInfo}
          onTimeslotClick={handleTimeslotClick}
          selectedDate={selectedDate.clone().format('YYYY-MM-DD')}
        >
          <div
            style={{
              marginBottom: '1rem',
              display: 'flex',
              flexDirection: 'column',
              gap: '0.5rem',
            }}
          >
            <div
              style={{
                display: 'flex',
                gap: '1rem',
              }}
              className="no-print"
            >
              <BookingCreationDialog />
              <BlockBookingCreationDialog />
              <ReactToPrint
                trigger={() => <Button variant="contained" color="secondary">Print Calendar</Button>}
                content={() => calendarRef.current}
              />
            </div>
          </div>
        </Calendar>
      </div>

      {calandarPaymentDialogOpen && (
        <RecordPaymentDialog
          isOpen={calandarPaymentDialogOpen}
          calendarPayment
          subscriptionSelected
          dialogTitle={`Record payment for subscription: ${subscription?.subscriptionName}`}
          requestMultipleRecordPayment={() => requestMultipleRecordPayment()}
          subscriptionLink={subscriptionLink}
          subscription={subscription}
          selectedReservations={selectedRows}
          resetSelectedRows={() => dispatch(resetSelectedRows())}
        />
      )}
      {calendarReservationCreationLoading && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            zIndex: 9999,
            gap: '1rem',
          }}
        >
          <CircularProgress />
          <Typography variant="h5" style={{ color: 'white' }}>
            Fetching facility data...
          </Typography>
        </div>
      )}
    </div>

  );
};

CalendarView.propTypes = {
  history: PropTypes.shape().isRequired,
};

export default CalendarView;

/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  Typography,
  Button,
} from '@material-ui/core';
import StripeCheckout from '@pitchbooking-dev/pb-shared/lib/components/Stripe/StripeCheckout';
import { getCurrencySymbol } from '@pitchbooking-dev/pb-shared/lib/helpers';
import { useDispatch } from 'react-redux';
import {
  useCompany,
} from '../../../hooks';
import * as reservationActions from '../../../reducers/reservationsReducer';

export const CardNotPresent = ({
  paymentMethod, booking, onSuccess, metadata, preSubmitAction,
}) => {
  const dispatch = useDispatch();
  const {
    products, stripeId, currency,
  } = useCompany();
  const [waiveServiceFee, setWaiveServiceFee] = useState(false);

  if (paymentMethod !== 'CARD_NOT_PRESENT' || products?.cardNotPresent !== 'ENABLED') return null;

  return (
    <div>
      <Typography variant="body2" style={{ marginBottom: '1rem' }}>
        You can now capture payments from your customers over
        the phone or directly from a saved payment method!
      </Typography>
      {!waiveServiceFee ? (
        <div style={{
          display: 'flex', justifyContent: 'center', gap: 3, alignItems: 'center',
        }}
        >
          <p>Total Price includes service fee</p>
          <Button style={{ height: 24 }} variant="contained" color="secondary" onClick={() => { setWaiveServiceFee(true); }}>Waive Service Fees</Button>
        </div>
      ) : (
        <div style={{
          display: 'flex', justifyContent: 'center', gap: 3, alignItems: 'center',
        }}
        >
          <p>Service Fee has been waived</p>
          <Button style={{ height: 24 }} variant="contained" color="secondary" onClick={() => { setWaiveServiceFee(false); }}>Include Service Fees</Button>
        </div>
      )}
      <StripeCheckout
        mode="PAYMENT_INTENT"
        transactionType={
          booking?.type === 'SUBSCRIPTION'
            ? 'SUBSCRIPTION_PAYMENT'
            : 'RESERVATION_PAYMENT'
        }
        transactionId={booking?.id}
        companyStripeId={stripeId}
        currencySymbol={getCurrencySymbol(currency)}
        redirectUrl={`${window.location.origin}/bookings`}
        metadata={{
          isCardNotPresent: true,
          waiveServiceFee,
          ...(booking?.type === 'SUBSCRIPTION' ? {
            slot: booking.slot,
          } : {}),
          ...(metadata || {}),
        }}
        onSuccess={onSuccess}
        preSubmitAction={preSubmitAction ?? null}
        onError={() => {
          dispatch(reservationActions.updateRecordPaymentStore({
            isPaymentProcessing: false,
          }));
        }}
      />
    </div>
  );
};

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import PitchbookingUserOnly from '../../helpers/PitchbookingUserOnly';
import { updateCompanyProduct, saveCompanyProducts } from '../../reducers/companiesReducer';

class CompanyProducts extends React.Component {
  render() {
    const { companies, doUpdateCompanyProduct, doSaveCompanyProducts } = this.props;
    const { id, name, products } = companies.companyInfo;

    const statuses = [
      { value: 'ENABLED', label: 'ENABLED', color: 'green' },
      { value: 'DISABLED', label: 'DISABLED', color: 'red' },
      { value: 'REQUESTED', label: 'REQUESTED', color: 'orange' },
      { value: 'HIDDEN', label: 'HIDDEN', color: 'grey' },
    ];

    return (
      <>
        <PitchbookingUserOnly>
          <Card>
            <CardContent>
              <p>
                <b>
                  {name}
                  {' '}
                  Products
                </b>
                {' '}
                This is a list of products that are available to be enabled or disabled.
              </p>
              <div>
                {Object.entries(products).map(([key, value]) => (
                  <div key={key} style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                    <p style={{ marginRight: '10px', width: '140px' }}>{key}</p>
                    <select
                      value={value}
                      onChange={(e) => doUpdateCompanyProduct(key, e.target.value)}
                      style={{
                        color: statuses.find((status) => status.value === value).color,
                        fontWeight: 'bold',
                      }}
                      disabled={key === 'multiSiteSelector'}
                    >
                      {statuses.map((status) => (
                        <option
                          key={status.value}
                          value={status.value}
                          style={{ color: status.color }}
                        >
                          {status.label}
                        </option>
                      ))}
                    </select>
                  </div>
                ))}
              </div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => doSaveCompanyProducts(id)}
              >
                Save
              </Button>
            </CardContent>
          </Card>
        </PitchbookingUserOnly>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { users, companies } = state;
  const { isLoading } = users;
  return {
    users, isLoading, companies,
  };
}

CompanyProducts.propTypes = {
  doUpdateCompanyProduct: PropTypes.func.isRequired,
  doSaveCompanyProducts: PropTypes.func.isRequired,
  companies: PropTypes.shape().isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  doUpdateCompanyProduct: (id, value) => dispatch(updateCompanyProduct(id, value)),
  doSaveCompanyProducts: (id) => dispatch(saveCompanyProducts(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyProducts);

/* eslint-disable react/prop-types */
import React from 'react';
import {
  Select, MenuItem, FormControl, Checkbox, CircularProgress,
  ListItemText, Input, Button, Typography,
} from '@material-ui/core/';
import { useSiteSelector } from '../../hooks';
import { isValidUUID } from '../../utils';

const MultiSiteSelector = ({ disabled, calendarIsLoading }) => {
  const {
    sites,
    selectedSites,
    changeSite,
    updateSelectedSites,
    savePreferredSites,
    savePreferredSitesLoading,
  } = useSiteSelector();

  const allSitesSelected = selectedSites.length === sites.length;
  const allSitesIndeterminate = selectedSites.length > 0 && !allSitesSelected;

  const handleChange = (event) => {
    const { value } = event.target;
    if (value.includes('all')) {
      // If "All Sites" is selected, select or deselect all
      updateSelectedSites(allSitesSelected ? [] : sites.map((site) => site.id));
    } else {
      // Handle individual site selection
      updateSelectedSites(value.filter(isValidUUID));
    }
  };

  return (
    calendarIsLoading ? <CircularProgress size={24} color="secondary" /> : (
      <FormControl variant="outlined" disabled={disabled || calendarIsLoading}>
        <Select
          labelId="site-selector-label"
          multiple
          value={selectedSites}
          onChange={handleChange}
          input={<Input />}
          renderValue={(selected) => selected.map((id) => sites.find((site) => site.id === id)?.name || 'N/A').join(', ')}
        >
          <MenuItem value="all">
            <Checkbox
              indeterminate={allSitesIndeterminate}
              checked={allSitesSelected}
            />
            <ListItemText primary="All Sites" />
          </MenuItem>
          {sites.map((site) => (
            <MenuItem key={site.id} value={site.id} disabled={calendarIsLoading}>
              <Checkbox checked={selectedSites.includes(site.id)} />
              <ListItemText primary={site?.name} />
            </MenuItem>
          ))}
          <div style={{ display: 'flex', flexDirection: 'column', alignContent: 'center' }}>
            <MenuItem>
              <Button
                variant="contained"
                color="primary"
                onClick={changeSite}
                disabled={calendarIsLoading || selectedSites.length === 0}
                style={{ width: '100%' }}
                type="submit"
              >
                <Typography variant="button">
                  {calendarIsLoading ? <CircularProgress size={24} color="secondary" /> : 'Update Sites'}
                </Typography>
              </Button>
            </MenuItem>
            {!(calendarIsLoading || selectedSites.length === 0) && (
            <MenuItem>
              <Button
                variant="contained"
                onClick={savePreferredSites}
                style={{ width: '100%' }}
                type="button"
                disabled={savePreferredSitesLoading}
              >
                <Typography variant="button">
                  {savePreferredSitesLoading ? <CircularProgress size={24} color="secondary" /> : 'Save as Default View'}
                </Typography>
              </Button>
            </MenuItem>
            )}
          </div>
        </Select>
      </FormControl>
    )
  );
};

export default MultiSiteSelector;

/* eslint-disable consistent-return */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
  Link,
} from '@material-ui/core';
import Moment from 'moment-timezone';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import { generateTotalWithTaxText } from '@pitchbooking-dev/pb-shared/lib/helpers/calculationHelpers';
import ExtensionTwoToneIcon from '@material-ui/icons/ExtensionTwoTone';
import ViewAttendeeDialog from '../../events/eventsView/ViewAttendeeDialog';
import { PartialPaymentsModal } from './bookingsView/PartialPaymentsModal';
import { omitFormProperties } from '../../../utils';

function DetailPaneTitle(props) {
  const { title } = props;
  return (
    <Typography style={{ padding: '20px 16px 0', color: '#6699FF' }} variant="h6">{title}</Typography>
  );
}

class TableRowCollapsed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isViewPaymentsOpen: false,
    };
  }

  render() {
    const { isViewPaymentsOpen } = this.state;
    const {
      reservation, stripeId, currencySym,
    } = this.props;
    const {
      facilityName, paymentStatusText, facility, startTime, endTime, successfulOrder,
      allocations, amenityNames, ageGroupBookingModifier, eventBookingModifier, type, addonNames,
      invoices, payLaterDate,
    } = reservation;
    const { timezone } = facility;
    const datePaymentMade = successfulOrder ? successfulOrder.paidAt : null;
    const stripePaymentId = successfulOrder ? successfulOrder.paymentId : null;
    const receivedByUser = successfulOrder ? successfulOrder.createdByUser : null;
    let allocationNotes = '';
    allocations.forEach((allocation) => {
      if (allocation.notes !== null) {
        allocationNotes = allocationNotes.concat(`${allocation.notes} `);
      }
    });
    const allocationFormIndex = allocations.findIndex((allocation) => allocation.formData);
    const createdByUserFullname = allocations[0] ? `${allocations[0].createdByUser?.firstName} ${allocations[0].createdByUser?.lastName}` : '';
    const keycodes = allocations[0] ? allocations[0].keycodes : null;

    return ([
      <>
        <div
          style={{
            textAlign: 'left',
            display: 'flex',
          }}
        >
          <div className="cell-detail-pane">
            <DetailPaneTitle title="Time" />
            <List>
              <ListItem>
                <ListItemText
                  primary={Moment(reservation.createdAt).tz(timezone).format('LLL')}
                  secondary="Created At"
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={Moment(startTime).tz(timezone).format('LLL')}
                  secondary="Start Time"
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={Moment(endTime).tz(timezone).add(1, 'seconds').format('LLL')}
                  secondary="End Time"
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={keycodes?.keycodes ? keycodes.keycodes.map((kc) => `${kc?.keycode}`) : 'N/A'}
                  secondary="Keycode"
                />
              </ListItem>
            </List>
          </div>
          <div className="cell-detail-pane">
            <DetailPaneTitle title="Booking" />
            <List>
              <ConditionallyVisible condition={!type === 'SUBSCRIPTION'}>
                <ListItem>
                  <ListItemText
                    primary={createdByUserFullname}
                    secondary="Created By"
                  />
                </ListItem>

                <ConditionallyVisible condition={allocationNotes.length > 0}>
                  <ListItem>
                    <ListItemText
                      primary={allocationNotes}
                      secondary="Additional Information"
                    />
                  </ListItem>
                </ConditionallyVisible>
                <ConditionallyVisible condition={ageGroupBookingModifier
                  !== null && ageGroupBookingModifier.name}
                >
                  <ListItem>
                    <ListItemText
                      primary={ageGroupBookingModifier !== null ? ageGroupBookingModifier.name : 'Not Set'}
                      secondary="Age Group"
                    />
                  </ListItem>
                </ConditionallyVisible>
                <ConditionallyVisible condition={eventBookingModifier
                  !== null && eventBookingModifier.name}
                >
                  <ListItem>
                    <ListItemText
                      primary={eventBookingModifier !== null ? eventBookingModifier.name : 'Not Set'}
                      secondary="Event"
                    />
                  </ListItem>
                </ConditionallyVisible>
              </ConditionallyVisible>
              <div>
                <ListItem>
                  <ListItemText
                    primary={reservation.notes || 'Not Set'}
                    secondary="Admin Notes"
                  />
                </ListItem>
              </div>
            </List>
          </div>
          <div className="cell-detail-pane">
            <DetailPaneTitle title="Payment" />
            <List>
              <ListItem>
                <ListItemText
                  primary={`${currencySym}${reservation.total.toFixed(2)} (${currencySym}${reservation.totalExTax.toFixed(2)} + ${currencySym}${reservation.tax.toFixed(2)} tax)`}
                  secondary="Price"
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={reservation?.partialPaymentOrders?.length > 0
                    ? (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          startIcon={<ExtensionTwoToneIcon />}
                          onClick={() => this.setState({ isViewPaymentsOpen: true })}
                        >
                          View Payments
                        </Button>
                        <PartialPaymentsModal
                          reservation={reservation}
                          isOpen={isViewPaymentsOpen}
                          onClose={() => this.setState({ isViewPaymentsOpen: false })}
                        />
                      </>
                    )
                    : paymentStatusText}
                  secondary={
                    reservation?.partialPaymentOrders?.length > 0
                      ? 'Payment Status - Partially Paid'
                      : 'Payment Status'
                  }
                />
              </ListItem>
              {reservation.totalPaid && (
                <ListItem>
                  <ListItemText
                    primary={`Paid: ${currencySym}${reservation.totalPaid.toFixed(2)}`}
                    secondary={`Remaining: ${currencySym}${(reservation.total - reservation.totalPaid).toFixed(2)}`}
                  />
                </ListItem>
              )}
              {stripePaymentId
                && (
                  <ListItem>
                    <a target="_blank" rel="noopener noreferrer" href={`https://dashboard.stripe.com/${stripeId}/${stripePaymentId.includes('sub_') ? 'subscriptions' : 'payments'}/${stripePaymentId}`}>
                      <ListItemText
                        primary="View in Stripe"
                      />
                    </a>
                  </ListItem>
                )}
              {receivedByUser
                && (
                  <ListItem>
                    <ListItemText
                      primary={`${receivedByUser.firstName} ${receivedByUser.lastName}`}
                      secondary="Recorded Payment"
                    />
                  </ListItem>
                )}
              {datePaymentMade
                && (
                  <ListItem>
                    <ListItemText
                      primary={`${Moment(datePaymentMade).format('LLL')}`}
                      secondary="Payment Taken"
                    />
                  </ListItem>
                )}
              {payLaterDate
                && (
                  <ListItem>
                    <ListItemText
                      primary={`${Moment(payLaterDate).format('LLL')}`}
                      secondary="Payment Defered Until"
                    />
                  </ListItem>
                )}
              {invoices && invoices.length > 0
                ? (
                  <ListItem>
                    <ListItemText
                      primary={invoices[0]?.invoiceNumber}
                      secondary="Invoice Number"
                    />
                  </ListItem>
                ) : null}
            </List>
          </div>
          <div className="cell-detail-pane">
            <DetailPaneTitle title="Booker" />
            <List>
              <Link
                href={`/users/${reservation.user?.id}`}
              >
                <ListItem button>
                  <ListItemText
                    primary={reservation.fullName}
                    secondary="Name"
                  />
                </ListItem>
              </Link>
              <ListItem>
                <ListItemText
                  primary={reservation.user.teamName || 'Not Set'}
                  secondary="Team Name"
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={reservation.user.email}
                  secondary="Email Address"
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={reservation.user.phone ? `+${reservation.user.dialCode}${reservation.user.phone}` : 'Not Set'}
                  secondary="Mobile Number"
                />
              </ListItem>
            </List>
          </div>
          <div className="cell-detail-pane">
            <DetailPaneTitle title="Facility" />
            <List>
              <ListItem>
                <ListItemText
                  primary={facilityName || reservation.facility?.name || 'Not Set'}
                  secondary="Name"
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={reservation.sport ? reservation.sport : 'Not Set'}
                  secondary="Sport"
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={reservation.cancellationReason
                    ? reservation.cancellationReason
                    : 'N/A'}
                  secondary="Cancellation Resaon"
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={reservation.cancellationDate
                    ? Moment(reservation.cancellationDate).tz(timezone).format('LLL')
                    : 'N/A'}
                  secondary="Cancellation Date"
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={reservation.cancelledByUser
                    ? `${reservation.cancelledByUser?.firstName} ${reservation.cancelledByUser?.lastName}`
                    : 'N/A'}
                  secondary="Cancelled By Name"
                />
              </ListItem>
              <ConditionallyVisible condition={amenityNames && amenityNames.length > 0}>
                <ListItem>
                  <ListItemText
                    primary={amenityNames ? amenityNames.join(', ') : 'None booked'}
                    secondary="Amenities"
                  />
                </ListItem>
              </ConditionallyVisible>
              <ConditionallyVisible condition={addonNames && addonNames.length > 0}>
                <ListItem>
                  <ListItemText
                    primary={addonNames ? addonNames.join(', ') : 'None booked'}
                    secondary="Addons"
                  />
                </ListItem>
              </ConditionallyVisible>
            </List>
          </div>
        </div>
        <div style={{ display: 'flex', padding: '25px' }}>
          {type !== 'SUBSCRIPTION'
            ? (
              <a href={`/bookings/${reservation.id}`} style={{ textDecoration: 'none' }}>
                <Button variant="contained" color="secondary">
                  View Booking
                </Button>
              </a>
            ) : (
              <a href={`/subscriptions/${reservation.subscriptionId}`} style={{ textDecoration: 'none' }}>
                <Button variant="contained" color="secondary">
                  View Subscription
                </Button>
              </a>
            )}
          {allocationFormIndex >= 0 && (
            <ViewAttendeeDialog
              formData={omitFormProperties(
                reservation.allocations[allocationFormIndex].formData,
              )}
            />
          )}
        </div>
      </>,
    ]);
  }
}

TableRowCollapsed.propTypes = {
  reservation: PropTypes.shape().isRequired,
  stripeId: PropTypes.string,
  currencySym: PropTypes.string.isRequired,
};
DetailPaneTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

TableRowCollapsed.defaultProps = {
  stripeId: null,
};

export default (TableRowCollapsed);

import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import DateRangePickerWrapper from './DateRangePickerWrapper';
import '../../styles/calendar.css';
import { updateOrdersStore } from '../../reducers/ordersReducer';

const quickDateOptions = [
  { label: 'Today', value: 'TODAY' },
  { label: 'Yesterday', value: 'YESTERDAY' },
  { label: 'Next 7 Days', value: 'NEXT7' },
  { label: 'Past 7 Days', value: 'PAST7' },
  { label: 'This weekend', value: 'THISWEEKEND' },
  { label: 'Last weekend', value: 'LASTWEEKEND' },
  { label: 'Month remaining', value: 'TILLENDOFMONTH' },
  { label: 'Month to date', value: 'MONTHTODATE' },
  { label: 'Quarter to date', value: 'QUARTERTODATE' },
  { label: 'This Month', value: 'FULLMONTH' },
  { label: 'Next Month', value: 'NEXTMONTH' },
  { label: 'Last Month', value: 'LASTMONTH' },
  { label: 'Custom', value: 'CUSTOM' },
];

const DateRangePickerAndQuickDatesWrapper = ({
  includeQuickOptions,
  includePaymentsByTime,
  onOrderHistoryDatesChange,
}) => {
  const dispatch = useDispatch();
  const { timezone, dateLocale, products } = useSelector((state) => state.companies?.companyInfo);
  const { selectedStartDate, selectedEndDate } = useSelector((state) => state.orders);

  const [quickDateType, setQuickDateType] = useState(quickDateOptions[0]);
  const currentTz = timezone || moment.tz.guess();

  const isPaymentsByTimeEnabled = products?.paymentsByTime === 'ENABLED' ?? false;

  const onDatesChange = (options) => {
    dispatch(updateOrdersStore({
      selectedStartDate: options.startDate,
      selectedEndDate: options.endDate,
    }));
    if (onOrderHistoryDatesChange) {
      onOrderHistoryDatesChange({
        selectedStartDate: options.startDate,
        selectedEndDate: options.endDate,
      });
    }
  };

  // Using layout effect to ensure this runs before the component is mounted
  useLayoutEffect(() => {
    if (!selectedStartDate || !selectedEndDate) {
      onDatesChange({
        startDate: moment().tz(currentTz).startOf('day'),
        endDate: moment().tz(currentTz).endOf('day'),
      });
    }
  }, []);

  const getTimeParts = (value) => {
    const timeParts = value.split(':');
    const hour = parseInt(timeParts[0], 10);
    const minute = parseInt(timeParts[1], 10);
    return { hour, minute };
  };

  const getCurrentTimeParts = () => {
    const currentStartHour = selectedStartDate ? selectedStartDate.hours() : 0;
    const currentStartMinute = selectedStartDate ? selectedStartDate.minutes() : 0;
    const currentEndHour = selectedEndDate ? selectedEndDate.hours() : 23;
    const currentEndMinute = selectedEndDate ? selectedEndDate.minutes() : 59;
    return {
      currentStartHour, currentStartMinute, currentEndHour, currentEndMinute,
    };
  };

  const handleQuickDateChange = (option) => {
    setQuickDateType(option);

    // Preserve current time from selectedStartDate and selectedEndDate
    const {
      currentStartHour,
      currentStartMinute,
      currentEndHour,
      currentEndMinute,
    } = getCurrentTimeParts();

    // Create base moment objects with current timezone
    const baseMoment = moment().tz(currentTz);

    let startDate;
    let endDate;

    switch (option.value) {
      case 'TODAY':
        startDate = baseMoment.clone().hours(currentStartHour).minutes(currentStartMinute);
        endDate = baseMoment.clone().hours(currentEndHour).minutes(currentEndMinute);
        break;
      case 'YESTERDAY':
        startDate = baseMoment.clone().subtract(1, 'd').hours(currentStartHour).minutes(currentStartMinute);
        endDate = baseMoment.clone().subtract(1, 'd').hours(currentEndHour).minutes(currentEndMinute);
        break;
      case 'NEXT7':
        startDate = baseMoment.clone().hours(currentStartHour).minutes(currentStartMinute);
        endDate = baseMoment.clone().add(7, 'days').hours(currentEndHour).minutes(currentEndMinute);
        break;
      case 'PAST7':
        startDate = baseMoment.clone().subtract(7, 'days').hours(currentStartHour).minutes(currentStartMinute);
        endDate = baseMoment.clone().hours(currentEndHour).minutes(currentEndMinute);
        break;
      case 'THISWEEKEND':
        startDate = baseMoment.clone().day(6).hours(currentStartHour).minutes(currentStartMinute);
        endDate = baseMoment.clone().day(7).hours(currentEndHour).minutes(currentEndMinute);
        break;
      case 'LASTWEEKEND':
        startDate = baseMoment.clone().subtract(7, 'days').day(6).hours(currentStartHour)
          .minutes(currentStartMinute);
        endDate = baseMoment.clone().subtract(7, 'days').day(7).hours(currentEndHour)
          .minutes(currentEndMinute);
        break;
      case 'TILLENDOFMONTH':
        startDate = baseMoment.clone().hours(currentStartHour).minutes(currentStartMinute);
        endDate = baseMoment.clone().endOf('month').hours(currentEndHour).minutes(currentEndMinute);
        break;
      case 'MONTHTODATE':
        startDate = baseMoment.clone().startOf('month').hours(currentStartHour).minutes(currentStartMinute);
        endDate = baseMoment.clone().hours(currentEndHour).minutes(currentEndMinute);
        break;
      case 'QUARTERTODATE':
        startDate = baseMoment.clone().quarter(baseMoment.quarter()).startOf('quarter')
          .hours(currentStartHour)
          .minutes(currentStartMinute);
        endDate = baseMoment.clone().hours(currentEndHour).minutes(currentEndMinute);
        break;
      case 'FULLMONTH':
        startDate = baseMoment.clone().startOf('month').hours(currentStartHour).minutes(currentStartMinute);
        endDate = baseMoment.clone().endOf('month').hours(currentEndHour).minutes(currentEndMinute);
        break;
      case 'NEXTMONTH':
        startDate = baseMoment.clone().add(1, 'month').startOf('month')
          .hours(currentStartHour)
          .minutes(currentStartMinute);
        endDate = baseMoment.clone().add(1, 'month').endOf('month')
          .hours(currentEndHour)
          .minutes(currentEndMinute);
        break;
      case 'LASTMONTH':
        startDate = baseMoment.clone().subtract(1, 'month').startOf('month')
          .hours(currentStartHour)
          .minutes(currentStartMinute);
        endDate = baseMoment.clone().subtract(1, 'month').endOf('month')
          .hours(currentEndHour)
          .minutes(currentEndMinute);
        break;
      case 'CUSTOM':
      default:
        startDate = baseMoment.clone().hours(currentStartHour).minutes(currentStartMinute);
        endDate = baseMoment.clone().hours(currentEndHour).minutes(currentEndMinute);
        break;
    }

    onDatesChange({ startDate, endDate });
  };

  const handleDateChange = (options) => {
    const {
      currentStartHour,
      currentStartMinute,
      currentEndHour,
      currentEndMinute,
    } = getCurrentTimeParts();

    const startDate = moment(options.startDate).tz(currentTz)
      .hours(currentStartHour).minutes(currentStartMinute);
    const endDate = moment(options.endDate).tz(currentTz)
      .hours(currentEndHour).minutes(currentEndMinute);

    onDatesChange({ startDate, endDate });
  };

  const handleStartTimeChange = (event) => {
    const { hour, minute } = getTimeParts(event.target.value);
    const newStart = moment(selectedStartDate).tz(currentTz, true).hours(hour).minutes(minute);

    onDatesChange({ startDate: newStart, endDate: selectedEndDate });
  };

  const handleEndTimeChange = (event) => {
    const { hour, minute } = getTimeParts(event.target.value);
    const newEnd = moment(selectedEndDate).tz(currentTz, true).hours(hour).minutes(minute);

    onDatesChange({ startDate: selectedStartDate, endDate: newEnd });
  };

  return (
    <>
      <ConditionallyVisible condition={includeQuickOptions}>
        <div style={{
          display: 'flex',
          flexGrow: 0,
          alignItems: 'center',
          backgroundColor: 'white',
          borderRadius: '4px',
          padding: '8px',
          gap: '8px',
          flexShrink: 1,
        }}
        >
          <Autocomplete
            value={quickDateType}
            onChange={(event, option) => handleQuickDateChange(option)}
            options={quickDateOptions}
            getOptionLabel={(option) => option.label}
            style={{ minWidth: 180 }}
            renderInput={(params) => (
              <TextField {...params} label="" variant="outlined" />
            )}
            disableClearable
          />
        </div>
      </ConditionallyVisible>
      <div style={{
        display: 'flex',
        flexGrow: 0,
        alignItems: 'center',
        backgroundColor: 'white',
        borderRadius: '4px',
        padding: '8px',
        gap: '8px',
        flexShrink: 0,
      }}
      >
        <DateRangePickerWrapper
          startDate={selectedStartDate}
          endDate={selectedEndDate}
          onDatesChange={handleDateChange}
          noBorder
          numberOfMonths={1}
          minimumNights={0}
          displayFormat={
            dateLocale && dateLocale === 'en-US'
              ? 'MMM DD, YYYY'
              : 'DD MMM YYYY'
          }
          isOutsideRange={() => false}
        />
      </div>
      {(includePaymentsByTime && isPaymentsByTimeEnabled) && (
        <div style={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: 'white',
          borderRadius: '4px',
          padding: '8px',
          gap: '8px',
          flexShrink: 0,
          maxWidth: '280px',
        }}
        >
          <div style={{ width: '120px', flexShrink: 0 }}>
            <TextField
              type="time"
              value={selectedStartDate?.format('HH:mm')}
              onChange={handleStartTimeChange}
              InputLabelProps={{ shrink: true }}
              inputProps={{
                step: 300,
              }}
              size="small"
              fullWidth
              variant="outlined"
            />
          </div>
          <div style={{ width: '120px', flexShrink: 0 }}>
            <TextField
              type="time"
              value={selectedEndDate?.format('HH:mm')}
              onChange={handleEndTimeChange}
              InputLabelProps={{ shrink: true }}
              inputProps={{
                step: 300,
              }}
              size="small"
              fullWidth
              variant="outlined"
            />
          </div>
        </div>
      )}
    </>
  );
};

DateRangePickerAndQuickDatesWrapper.propTypes = {
  includeQuickOptions: PropTypes.bool,
  includePaymentsByTime: PropTypes.bool,
  onOrderHistoryDatesChange: PropTypes.func,
};

DateRangePickerAndQuickDatesWrapper.defaultProps = {
  includeQuickOptions: true,
  includePaymentsByTime: false,
  onOrderHistoryDatesChange: null,
};

export default DateRangePickerAndQuickDatesWrapper;
